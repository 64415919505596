/* eslint-disable eqeqeq */

import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics'
import Menu from 'react-burger-menu/lib/menus/slide'
import Logo from '../atoms/Logo'
import './nav.sass';

class NavInner extends React.Component {
  render() {
    let layout = this.props.layout
    let root = this.props.data.wagtail.site.rootPage

    let logoLink =
      <OutboundLink href="https://www.newamerica.org/" className="nav__link">
        <Logo className="nav__logo" />
      </OutboundLink>

    let homeLink = layout != "homepage" &&
      <Link to={root.url} className="nav__link">
        {root.title}
      </Link>
    
    let links = this.props.data.wagtail.nav.items.map((link, index) =>
      <Link to={`${link.page.url}`} className="nav__link" key={index}>
        {link.text}
      </Link>)

    return (
      <nav className={`nav nav--${layout}`}>
        <div className="nav__links">
          {layout == "homepage" ? logoLink : <Logo small className="nav__logo nav__logo--small" />}
          {homeLink}
        </div>
        <div className="nav__links">{links}</div>
        <Menu right>
          {logoLink}
          {homeLink}
          {links}
        </Menu>
      </nav>
    )
  }

}

Nav.propTypes = {
  layout: PropTypes.string,
  data: PropTypes.object,
}

export function Nav(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          wagtail {
            site (id: 2) {
              rootPage {
                title
                url
              }
            }
            nav: setting (name: "NavMenu") {
              ... on NavMenu {
                items {
                  page {
                    url
                  }
                  text
                }
              }
            }
          }
        }
      `}
      render={data => <NavInner data={data} {...props} />}
    />
  )
}

export default Nav
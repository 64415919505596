import React from 'react'

const Logo = ({ small, ...props }) =>
  small ?
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165 119" {...props}>
      <path d="M.1 96.4h164.6V119H.1zM.1 48.6h164.6v23.9H.1zM40.3.9h124.4v23.9H40.3zM13.2 26.4c7.3 0 13.2-5.9 13.2-13.2C26.4 5.9 20.5 0 13.2 0 5.9 0 0 5.9 0 13.2c0 7.3 5.9 13.2 13.2 13.2"/>
    </svg>
  : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 588.17 144" {...props}>
      <title>New America</title>
      <path d="M.12 115.73h190.31v27.17H.12zM.12 58.4h190.31v28.67H.12zM46.6 1.05h143.82v28.67H46.6zM15.28 31.75c8.45 0 15.29-7.1 15.29-15.88S23.73 0 15.28 0 0 7.11 0 15.87s6.85 15.88 15.28 15.88M558.66 101l5.23 15.27h-10.47zM573 142.87h15.19l-22-60.36H551.1l-20.75 60.36h14.06l4.89-14.39H568zm-54.65-17a16.93 16.93 0 01-11.35 4.54c-9.95 0-16.56-7.77-16.56-17.72S496.37 95 506.92 95a16.81 16.81 0 0111.41 4.54l8.5-11c-4.72-4.19-11.07-7.15-20.08-7.15-19 0-31.31 14-31.31 31.39 0 17.64 11.83 31.23 31.57 31.23a27.25 27.25 0 0019.74-7.84zM467 82.51h-14.33v60.36H467zm-45.9 12.55c3.17 0 5.15 2.19 5.15 5.5s-1.89 5.68-5.15 5.68h-5V95.06zm19.9 5.42c0-11.43-6.26-18-18.7-18h-20.43v60.36h14.24v-24.75H420l11.67 24.78H447l-13.56-27.57c4.8-3 7.55-8.1 7.55-14.82m-49.36 42.39v-13.6h-14.07v-10.39H391v-13.43h-13.44v-9.34h13.82v-13.6h-28.13v60.36zm-53.26-42.39v42.39h13.8V82.51h-21L318 123.41l-13.1-40.9h-21.45v60.36h13.2v-42.39l14.08 42.39h13.56zm-90 .52l5.24 15.27H243.1zm14.33 41.87h15.18l-22-60.36h-15.09L220 142.87h14.07l4.93-14.39h18.7zM260.53 1.05v36.46L238.2 1.05h-14.74V61.4h13.81V25.73l22.92 35.67h14.14V1.05zm24.83 0V61.4h28.39V47.81h-14.07V37.42h13.39V24h-13.39v-9.36h13.82V1.05zm106.74 0l-9.7 41.07L372 1.05h-15.34l-10.37 41.07-9.71-41.07h-15.69l16.39 60.35h16.38L364 23.47l10.16 37.93H391l16-60.35z"/>
    </svg>

export default Logo